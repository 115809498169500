import Icon from 'vue-awesome/components/Icon.vue';

Icon.register({
  'brands/sketchfab': {
    width: 32,
    height: 32,
    d: `M 17.577047,18.047092 27.731451,12.826178 V 24.128514 L 17.636059,29.751037
        17.577044,17.932339 M 4.0574513,12.826237 14.152904,18.161846 14.093832,29.751037
        3.9984394,24.128573 4.0574513,12.883584 M 15.864975,2.5565675 3.9984394,9.2117575
        15.864975,15.407991 27.790523,9.2117575 15.864975,2.5565675`
  }
});
